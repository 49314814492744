import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Button} from "../../ui/Button";
import toast from "react-hot-toast";

/*
Уважаемые пользователи!
Для продолжения работы на платформе необходимо заполнить форму.

ФИО…………………………………………………………………………………………………………………………………………….
e-mail и другие контакты……………………………………………………………………………………………………………
Университет или школа, студентов которой вы обучаете……………………………………….
Количество частных учеников……………………………………………………………………………………………………..
Участие в программе «Демография»………………………………………………………………………………………….
Режим использования платформы……………………………………………………………………………………………..
Использование AI………………………………………………………………………………………………………………………..
Другое (по желанию)…………………………………………………………………………………………………………………..
……………………………………………………………………………………………………………………………………………………..

Примечания:
e-mail обязателен; остальные данные – по вашему желанию (рекомендуем)
название учебного заведение – в любой форме (возможен вариант «прочерк»)
количество частных учеников – среднее по году; возможен «прочерк»
«Демография»: «прочерк», «участвовал(а)», «участвую в данный момент»
Режим: «создаю контент и учу», «использую готовое», «создаю для других»
AI: генерирую тексты, генерирую тексты и упражнения, синтезирую голос
Другое: критика и комплименты, желание разрабатывать контент или заниматься исследовательской деятельностью совместно с авторами платформы, идеи коммерциализации

 */

export const QUESTIONS = [
    {
        id: 'id-1',
        question: 'ФИО',
        type: 'text',
        description: 'ФИО',
        required: true,
        name: 'name'
    },
    {
        id: 'id-2',
        question: 'e-mail и другие контакты',
        type: 'text',
        description: 'e-mail и другие контакты',
        required: true,
        name: 'contacts'
    },
    {
        id: 'id-3',
        question: 'Университет или школа, студентов которой вы обучаете',
        type: 'text',
        description: 'в любой форме (возможен вариант «прочерк»)',
        required: true,
        name: 'university'
    },
    {
        id: 'id-4',
        question: 'Количество частных учеников',
        type: 'text',
        description: 'среднее по году; возможен «прочерк»',
        required: true,
        name: 'students'
    },
    {
        id: 'id-5',
        question: 'Участие в программе «Демография»',
        type: 'text',
        description: '«прочерк», «участвовал(а)», «участвую в данный момент»',
        required: true,
        name: 'demography'
    },
    {
        id: 'id-6',
        question: 'Режим использования платформы',
        type: 'text',
        description: '«создаю контент и учу», «использую готовое», «создаю для других»',
        required: true,
        name: 'mode'
    },
    {
        id: 'id-7',
        question: 'Использование AI',
        type: 'text',
        description: 'генерирую тексты, генерирую тексты и упражнения, синтезирую голос',
        required: true,
        name: 'ai'
    },
    {
        id: 'id-8',
        question: 'Другое (по желанию)',
        type: 'text',
        description: 'критика и комплименты, желание разрабатывать контент или заниматься исследовательской деятельностью совместно с авторами платформы, идеи коммерциализации',
        required: true,
        name: 'other'
    }
];

export default function PaywallFormTool(props) {
    const {
        onSave = d => {

        }
    } = props;
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data])

    let d = data || {};

    return (
        <Wrapper>

            <div>
                <h1>
                    Уважаемые пользователи!
                </h1>
                <p>
                    Для продолжения работы на платформе необходимо заполнить форму.
                </p>
            </div>

            {QUESTIONS.map((a, i) => {
                let value = d[a.name] || '';
                return (
                    <Section key={i}>
                        <Label>{a.question}</Label>
                        <Description>{a.description}</Description>
                        <Textarea
                            value={value}
                            onChange={e => {
                                let value = e.target.value;
                                setData({
                                    ...d,
                                    [a.name]: value
                                });
                            }}
                        />
                    </Section>
                )
            })}

            <div>
                <Button
                    onClick={() => {
                        // check that all fields are filled
                        let ok = true;
                        for (let i = 0; i < QUESTIONS.length; i++) {
                            let a = QUESTIONS[i];
                            if (d[a.name] == undefined || d[a.name] == '') {
                                ok = false;
                                break;
                            }
                        }
                        if (!ok) {
                            toast.error('Заполните все поля');
                            return;
                        }
                        onSave(data);
                    }}
                >
                    Сохранить
                </Button>
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Section = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
`;

const Description = styled.div`
  font-style: italic;
  margin-bottom: 5px;
  font-size: 12px;
  opacity: 0.7;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 60px;
  padding: 2px;
  margin: 2px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
`;