import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import NiceModal from "../../modals/NiceModal";

import content_writing_json from '../../../assets/lottie/content-writing.json'
import gallery_json from '../../../assets/lottie/gallery_json.json'
import tests_json from '../../../assets/lottie/employee-skill-list.json'
import video_json from '../../../assets/lottie/video-camera.json'
import dialog_json from '../../../assets/lottie/customer-reaction.json'
import exercise_json from '../../../assets/lottie/engineering-thinking.json'
import audio_json from '../../../assets/lottie/listening-audio.json'
import cards_json from '../../../assets/lottie/card-index.json'
import treadmill_json from '../../../assets/lottie/fitness-center.json'
import questionnaire_json from '../../../assets/lottie/questionnaire_lottie.json'
import buffer_json from '../../../assets/lottie/shopping-cart.json'


import LottieLoader from "../../loaders/LottieLoader";
import SlideItemEditTool from "../../lessons/tools/SlideItemEditTool";
import ReactHelper from "../../../helpers/ReactHelper";
import {isMobile} from "polotno/utils/screen";

const TYPES = [
    {
        label: 'Текст',
        value: 'text',
        lottie: content_writing_json
    },
    {
        label: 'Картинка',
        value: 'image',
        lottie: gallery_json,
        subItems: [
            {
                label: 'Редактор',
                value: 'image',
            },
            {
                label: 'Прямая загрузка',
                value: 'direct_image'
            },
            {
                label: 'AI generated',
                value: 'ai_image'
            },

        ]
    },
    {
        label: 'Видео',
        value: 'video',
        lottie: video_json
    },
    {
        label: 'Упражнение',
        value: 'exercise',
        lottie: exercise_json
    },
    {
        label: 'Карточки',
        value: 'card',
        lottie: cards_json
    },
    {
        label: 'Аудио',
        value: 'audio',
        lottie: audio_json,
        subItems: [
            {
                label: 'Прямая загрузка',
                value: 'audio'
            },
            {
                label: 'AI аудио',
                value: 'ai_audio',
            }
        ]
    },
    {
        label: 'Тест',
        value: 'test',
        lottie: tests_json
    },

    {
        label: 'Диалог',
        value: 'dialog',
        lottie: dialog_json
    },

    {
        label: 'Тредмил',
        value: 'treadmill',
        lottie: treadmill_json
    },

    {
        label: 'Опросник',
        value: 'questionnaire',
        lottie: questionnaire_json
    },

    {
        label: 'Буфер',
        value: 'buffer',
        lottie: buffer_json
    }
]

export default function AddItemTool(props) {
    const {
        items = [],
        topItemId = undefined,

        onItemsChange = newItems => {

        },
        loading = false

    } = props;

    let parentItemId = (topItemId == undefined) ? items[items.length - 1]?.id : topItemId;
    const [selectedType, setSelectedType] = useState();


    // console.log('AddItemTool: render: items = ', items);
    // console.log('AddItemTool: render: topItemId = ', topItemId);
    // console.log('AddItemTool: render: parentItemId = ', parentItemId);
    // console.log('AddItemTool: render: TYPES = ', TYPES);

    return (
        <Wrapper>

            <InnerPlaceholder>
                {TYPES.map((a, i) => {
                    let {subItems = []} = a;
                    return (
                        <TypeItem key={i} onClick={() => {
                            if (subItems.length == 0) {
                                setSelectedType(a.value);
                            }
                        }}>
                            <ItemLeftTypePlaceholder>
                                <LottieLoader json={a.lottie}/>
                            </ItemLeftTypePlaceholder>
                            <ItemTypeLabel>
                                {a.label}
                            </ItemTypeLabel>

                            {subItems.length == 0 ? null :
                                <InvSubPlaceholder className={'sub_items_placeholder'}>
                                    <InvSubPlaceholderInner>
                                        {subItems.map((b, j) => {
                                            return (
                                                <SubItem style={{}} key={j} onClick={() => {
                                                    setSelectedType(b.value);
                                                }}>
                                                    {b.label}
                                                </SubItem>
                                            )
                                        })}
                                    </InvSubPlaceholderInner>
                                </InvSubPlaceholder>
                            }

                        </TypeItem>
                    )
                })}
            </InnerPlaceholder>

            {selectedType == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedType(undefined);
                }}>
                    <InnerModalPlaceholder
                        style={{
                            width: (selectedType == 'image'  ? 'calc(100vw - 160px)' : !isMobile() ? '820px' : '100%'),
                            height: (selectedType == 'image' ? 'calc(100vh - 160px)' : !isMobile() ? 'auto' : '90vh'),
                            maxHeight: (selectedType == 'image' ? 'calc(100vh - 90px)' : '90vh'),
                            overflowY: (selectedType == 'image' ? 'none' : 'auto')
                        }}
                    >

                        <SlideItemEditTool
                            loading={loading}
                            type={selectedType}
                            selectedIds={items.map(xxx => xxx?.id).concat(items.map(xxx => xxx?.content?.id))}
                            onChange={(c, shouldClose = true) => {
                                console.log('SlideItemEditTool: onChange: c = ', c);
                                let dd = {
                                    type: selectedType,
                                    ...c,
                                    id: `t-${+new Date()}`
                                };
                                let n = 0;
                                for (let i in items) {
                                    if (items[i].id == parentItemId) {
                                        n = i;
                                    }
                                }
                                console.log('--->>> n = ', n);
                                let newItems = items.slice(0, +n + 1).concat([dd]).concat(items.slice(+n + 1));
                                console.log('onChange: newItems = ', newItems);
                                onItemsChange(newItems);
                                if (selectedType != 'video') {
                                    setSelectedType(undefined);
                                }
                            }}
                        />

                    </InnerModalPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const SubItem = styled.div`
  height: 72px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 167, 27, 1);
  border-radius: 4px;

  :hover {
    background: blue;
  }
`;

const InvSubPlaceholder = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  right: 0px;
  top: -34px;
  bottom: 0px;
  z-index: 2;
  background: rgb(255, 167, 27, 1);
`;

const InvSubPlaceholderInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const TypeItem = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  border: 2px solid rgb(255, 167, 27, 0.6);
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  padding-right: 15px;

  position: relative;

  min-width: 160px;

  :hover {
    background: rgb(255, 167, 27, 1);
    color: white;
    border: 2px solid rgb(255, 167, 27, 1);
  }

  :hover .sub_items_placeholder {
    display: block;
  }

`;

const ItemLeftTypePlaceholder = styled.div`
  height: 55px;
  width: 55px;
  margin-right: 0px;
`;

const ItemTypeLabel = styled.div`
  font-size: 12px;
`;

const InnerPlaceholder = styled.div`
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
`;

const InnerModalPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 820px;
  @media (max-width: 820px) {
    width: (calc(100vw - 40px));
  }
`;

const Wrapper = styled.div`

`;